<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>PARTENAIRES ASSOCIER MONNAIE</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <template>
                    <v-btn color="primary" dark class="mb-2" @click="nouveau()">
                        <v-icon dark>mdi-plus</v-icon> Nouveau
                    </v-btn>

                </template>

                <v-dialog v-model="dialog" max-width="850px">
                    <v-card>
                        <v-card-title class="text-h5">Formulaire de Compte</v-card-title>
                        <v-card-text>
                            <v-row>

                                <v-col cols="12" lg="6">
                                    <v-select :items="list_partenaire" v-model="detail_Formulaire.id_partenaire" item-value="id" outlined label="Partenaires">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.code_partenaire }} : {{ data.item.noms }} {{ data.item.prenoms }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code_partenaire }} : {{ data.item.noms }} {{ data.item.prenoms }}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" lg="6">
                                <v-select :items="list_monnaie" v-model="detail_Formulaire.id_monnaire" item-value="id" outlined label="Monnaies">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle_monnaie }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle_monnaie }}
                                        </template>
                                    </v-select>
                                </v-col>
                               

                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-toolbar>
        </template>

        <template v-slot:[`item.status`]="{ item }">
            <v-switch v-model="item.status" :color="item.status==1?'green':'red'" :label="item.status==1?'Activer':'Desactiver'" inset @change="Action_status(item)">

            </v-switch>

        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="red" large class="mr-2">
                    mdi-delete
                </v-icon>
            </v-btn>
            <v-btn icon @click="Edition(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>

        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Gestion des clients",
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '150px'

            },
            {
                text: "status",
                value: "status",
                divider: true,
                width: '50px'
            },

            {
                text: "LIBELLE MONNAIRE",
                align: "start",
                sortable: false,
                value: "monnaie.libelle_monnaie",
                divider: true,
                width: '150px'
            },
            {
                text: "CODE PARTENAIRE",
                align: "start",
                sortable: false,
                value: "partenaire.code_partenaire",
                divider: true,
                width: '150px'
            },
            {
                text: "PARTENAIRE",
                align: "start",
                sortable: false,
                value: "partenaire.noms",
                divider: true,
                width: '150px'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            id: '',
            id_partenaire: '',
            id_monnaire: '',

        },

        list: [],
        list_profils: [],
        selectprofil: 0,
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],
        valeur: '',
        etat: false,
        selecttype_recherche: '',
        libelle_recherche: '',
        list_status: ['Valider', 'Bloquer', 'En attente'],
        list_monnaie: [],
        list_partenaire: [],
      
    }),

    created() {
        this.readAll_parametre();
        this.readAll();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-monnaie-partenaire', fd);
            this.list = data;

        },
        readAll_parametre: async function () {
            let fd = new FormData();

            const data = await api.createUpdatedata('backoffice/all-monnaie', fd);
            this.list_monnaie = data;

            
            const data2 = await api.createUpdatedata('backoffice/all-partenaire', fd);
            this.list_partenaire = data2;

        },

        detail: async function (item) {
            this.detail_Formulaire.id = item.id;
            this.detail_Formulaire.id_partenaire = item.id_partenaire;
            this.detail_Formulaire.id_monnaire = item.id_monnaire;
            this.etat = true;
            this.dialog = true;
        },

        save: async function () {

            let fd = new FormData();
            fd.append('id_partenaire', this.detail_Formulaire.id_partenaire);
            fd.append('id_monnaire', this.detail_Formulaire.id_monnaire);
            const data = await api.createUpdatedata('backoffice/add-monnaie-partenaire', fd);

            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.dialog = false;
                this.readAll();
                this.clearItem();
            } else {
                this.clearItem();
                this.showAlert(data.message, 'error');
            }
           

        },
        nouveau: async function () {
            this.clearItem();
            this.dialog = true;
            this.etat = false;
        },

        Edition(item) {
            this.detail_Formulaire.id = item.id;
            this.detail_Formulaire.id_partenaire = item.id_partenaire;
            this.detail_Formulaire.id_monnaire = item.id_monnaire;
            this.etat = true;
            this.dialog = true;
        },

        clearItem() {

            this.detail_Formulaire.id = '';
            this.detail_Formulaire.id_partenaire = '';
            this.detail_Formulaire.id_monnaire = '';

        },

        async Action_status(item) {
            const data = await api.readAll('backoffice/etat-partenaire/' + item.id);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
            } else {
                this.showAlert(data.message, 'error');
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
